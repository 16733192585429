// external dependencies
import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

// local dependencies
import Section from './Section';
import TextBlock from './TextBlock';
import Image from './Image';
import EnhancedGrid from './EnhancedGrid';
import Video from './Video';

const StyledGrid = styled((props) => <EnhancedGrid {...props} />)`
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

// component definition
const TwoColTextAndImage = ({
  image,
  superText,
  headline,
  body,
  buttonText,
  buttonLink,
  reverse,
  videoSrc,
  videoPoster,
  altText
}) => {
  return (
    <Section>
      <StyledGrid
        reverse={reverse}
        container
        wrap='wrap'
        justifyContent='space-evenly'
        align='flex-start'
      >
        <Grid item xs={12} sm={12} md={6}>
          <TextBlock
            superText={superText}
            headline={headline}
            body={body}
            buttonText={buttonText}
            buttonLink={buttonLink}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          {videoSrc ? (
            <Video full videoSrc={videoSrc} videoPoster={videoPoster} />
          ) : (
            <Image image={image} altText={altText}/>
          )}
        </Grid>
      </StyledGrid>
    </Section>
  );
};

export default TwoColTextAndImage;
