// external dependencies
import React from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';

// gatsby dependencies
import { graphql } from 'gatsby';

// internal dependencies
import SEO from '../components/SEO';
import Hero from '../components/shared/Hero';
import TwoColTextAndImage from '../components/shared/TwoColTextAndImage';
import Cta from '../components/shared/Cta';

const StyledWrapper = styled.div`
  ul {
    margin: 10px 0 10px 25px;
  }
`;

// component definition
const ServicePageTemplate = ({ data }) => {
  const { pageTitle, metaDescription } = data.service;

  return (
    <>
      <SEO title={pageTitle} description={metaDescription} />
      <Hero
        short
        bkgImage={data.service.hero.image}
        superText={
          data.service.hero.supertext
            ? data.service.hero.supertext
            : 'Our Services'
        }
        headline={data.service.hero.headline}
        buttonText={data.service.hero.buttonText}
        buttonLink={data.service.hero.buttonLink}
      />
      <StyledWrapper>
        {/* Mapping over this section because twoColumnTextAndImageSection can have more than one item */}
        {data.service.twoColumnTextAndImageSection.map((item, i) => (
          <TwoColTextAndImage
            key={`${item.superText}-${i}`}
            superText={item.supertext}
            headline={item.title}
            body={parse(`${item.bodyCopy.bodyCopy}`)}
            buttonText={item.buttonText}
            buttonLink={item.buttonLink}
            image={item.image}
            videoSrc={item.video && item.video.file.url}
            videoPoster={item.video && item.videoPoster.file.url}
          />
        ))}
      </StyledWrapper>
      <Cta
        ctaSupertext={data.service.ctaSection.supertext}
        ctaHeadline={data.service.ctaSection.headline}
        phoneNumber={data.service.ctaSection.phoneNumber}
        email={data.service.ctaSection.email}
        ctaImage={data.service.ctaSection.image}
        altText={data.service.ctaSection.image.title}
      />
    </>
  );
};

export const query = graphql`
  query($slug: String!) {
    service: contentfulServicePageTemplate(slug: { eq: $slug }) {
      metaDescription
      pageTitle
      ctaSection {
        email
        headline
        phoneNumber
        supertext
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
        }
      }
      hero {
        buttonLink
        buttonText
        headline
        supertext
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
        }
      }
      twoColumnTextAndImageSection {
        bodyCopy {
          bodyCopy
        }
        buttonLink
        buttonText
        reverseOrder
        supertext
        video {
          file {
            url
          }
        }
        videoPoster {
          file {
            url
          }
        }
        title
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          title
        }
      }
    }
  }
`;

export default ServicePageTemplate;
